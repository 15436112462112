import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col } from 'antd';
import { page1 } from '../data';
import { useStaticQuery, graphql } from "gatsby"

import parse from "html-react-parser";
import SEO from '../components/seo';

export default function OurService() {

  const { allWpPage: {edges} } = useStaticQuery(graphql`
  query OurServiceQuery {
    allWpPage(filter: {parentId: {eq: "cG9zdDozMg=="}}) {
      edges {
        node {
          id
          title
          slug
          content
          uri
          featuredImage {
            node {
              id
              sourceUrl
              altText
            }
          }
        }
      }
    }
  }`)

  const getContent = (content) => {
    const startPharagraph = content.indexOf('<p>');
    const endPharagraph = content.indexOf('</p>');
    return parse(content.slice(startPharagraph, endPharagraph + 4));
  }

  const children = edges.reverse().map(x => x.node).map((card, i) => {
    // console.log(card);
    const { featuredImage: {node} } = card;

    return(<Col className="card-wrapper" key={i.toString()} md={8} xs={24}>
        <a className="card" href={card.url}>
          <h3>{card.title}</h3>
          {node && <img src={node.sourceUrl} alt={node.altText} />}
          <div className="card-body">
            <span className="title">{card.title}</span>
            <span className="description text-secondary">{getContent(card.content)}</span>
          </div>
        </a>
      </Col>
  )});

  return (
    <section className="page-wrapper page1">
      <QueueAnim
        component={Row}
        type="bottom"
        className="page row text-center"
        delay={500}
      >
        {children}
      </QueueAnim>
    </section>);
}