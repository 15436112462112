export const client = 'https://klepon.tech/cms/wp-content/uploads/';
export const assets = 'https://gw.alipayobjects.com/os/s/prod/antv/assets';
const base = 'https://antv.alipay.com/';
export const page1 = [
  {
    img: `${assets}/image/icon/g2-c94ef.svg`,
    href: `${base}zh-cn/g2/3.x/index.html`,
    title: 'G2',
    description: ' 是以数据为驱动，具有高度的易用性和扩展性的可视化图形语法。',
  },
  {
    img: `${assets}/image/icon/g6-b4554.svg`,
    title: 'G6',
    href: `${base}zh-cn/g6/1.x/index.html`,
    description: ' 是一套便捷、动态和富有交互的流程图和关系分析的图表库。',
  },
  {
    img: `${assets}/image/icon/f2-d360c.svg`,
    title: 'F2',
    href: `${base}zh-cn/f2/3.x/index.html`,
    description: ' 是一套精简、高性能、易扩展的的移动端图表库。',
  },
];

export const page3 = [
  {
    img: `../../content/assets/design-process.svg`,
    title: 'Prosess Desain',
    description: 'Menentukan desain Layouting, UI/UX produk',
  },
  {
    img: `../../content/assets/development-process.svg`,
    title: 'Proses Coding',
    description: 'Membuat logika bisnis pengembangan produk',
  },
  {
    img: `../../content/assets/publish-process.svg`,
    title: 'Proses Publikasi',
    description: 'Mempublikasikan/Finalisasi produk untuk dapat digunakan',
  },
];

export const page4 = [
  {
    name: '陈为',
    profile: '浙江大学计算机学院 CAD & CG 国家重点实验室教授 可视化专家',
    avatar: 'https://os.alipayobjects.com/rmsportal/CcFeLxXurbQmwrT.jpg',
    comment: '在多年可视化设计与开发的积累基础上，蚂蚁金服团队推出了 AntV 产品，这是工业界在基础可视化语法与实践方面发出的最强声音，也是工业界与学术界一道推进可视化研发进展的最佳利器。',
  },
  {
    name: '林峰',
    profile: '爱烹饪的数据可视化攻城狮',
    avatar: 'https://zos.alipayobjects.com/rmsportal/wtkIALmYDSmOIiAalkdv.jpg',
    comment: 'G2 是面粉，ECharts 是面条，皆微小但美好，因小食材怀大梦想，助力共筹东方巨龙崛起之盛宴，迎四海饕客。',
  },
];

export const page5 = [
  `${assets}/image/home/aliyun-f111c.png`,
  `${assets}/image/home/alipay-fceea.png`,
  `${assets}/image/home/tmall-cb94f.png`,
  `${assets}/image/home/taobao-ade5b.png`,
  `${assets}/image/home/mybank-da103.png`,
  `${assets}/image/home/jd-23e52.png`,
  `${assets}/image/home/yunos-2edef.png`,
  `${assets}/image/home/cainiao-40fc8.png`,
];

export const clients = [
  `${client}/2021/03/image-1.png`,
  `${client}/2021/03/image-4.png`,
  `${client}/2021/03/image-3.png`,
  `${client}/2021/04/image.png`,
];